window.addEventListener("scroll", function () {
  let scrollHeigh = window.scrollY;

  let navbar = document.querySelector(".header");

  if (scrollHeigh > 0 && !navbar.classList.contains("navbar-scroll")) {
    navbar.classList.add("navbar-scroll");
  } else if (scrollHeigh === 0 && navbar.classList.contains("navbar-scroll")) {
    navbar.classList.remove("navbar-scroll");
  }
});

if(document.querySelector(".waitlist__form")) {
  document
      .querySelector(".waitlist__form")
      .addEventListener("submit", function (event) {
        const emailInput = document.getElementById("emailInput");
        const errorMessage = document.querySelector(".error-message");

        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (
            emailInput.value.trim() === "" ||
            !emailPattern.test(emailInput.value)
        ) {
          event.preventDefault();

          errorMessage.style.display = "block";
          errorMessage.textContent =
              emailInput.value.trim() === ""
                  ? "Please enter your email address."
                  : "Please enter a valid email address.";
          emailInput.style.borderColor = "red";
        } else {
          errorMessage.style.display = "none";
          emailInput.style.borderColor = "";
        }
      });

}

document.addEventListener("DOMContentLoaded", function () {
  function includeHTML(callback) {
    var elements = document.querySelectorAll("[data-include-html]");
    var elementsToLoad = [];

    elements.forEach(function (element) {
      var file = element.getAttribute("data-include-html");
      if (file) {
        elementsToLoad.push({ element: element, file: file });
      }
    });

    var loadCount = 0;

    elementsToLoad.forEach(function (item) {
      fetch(item.file)
        .then(function (response) {
          if (response.status === 200) {
            return response.text();
          } else if (response.status === 404) {
            return "Page not found.";
          }
        })
        .then(function (data) {
          item.element.innerHTML = data;
          item.element.removeAttribute("data-include-html");
          loadCount++;
          if (
            loadCount === elementsToLoad.length &&
            typeof callback === "function"
          ) {
            callback();
          }
        })
        .catch(function (error) {
          console.error("Loading error:", error);
        });
    });
  }

  window.addEventListener("scroll", function () {
    let scrollHeigh = window.scrollY;

    let navbar = document.querySelector(".header");

    if (scrollHeigh > 0 && !navbar.classList.contains("navbar-scroll")) {
      navbar.classList.add("navbar-scroll");
    } else if (
      scrollHeigh === 0 &&
      navbar.classList.contains("navbar-scroll")
    ) {
      navbar.classList.remove("navbar-scroll");
    }
  });

  function deleteAnchor() {
    var str = document.location.toString();
    var index = str.indexOf("#");
    if (index > -1) {
      var newUrl = str.substring(0, index);
      history.replaceState(null, null, newUrl);
    }
  }

  function highlightActiveLink() {
    let currentPath = window.location.pathname.replace(/\/$/, "");
    const menuLinks = document.querySelectorAll(".burger-menu__link");

    if (currentPath === "" || currentPath === "/") {
      currentPath = "/index.html";
    }

    menuLinks.forEach(function (link) {
      const linkPath = new URL(
        link.getAttribute("href"),
        window.location.origin
      ).pathname.replace(/\/$/, "");

      if (linkPath === currentPath) {
        link.classList.add("burger-menu__link--active");
      }
    });
  }

  function hideErrorMessages() {
    const errorMessages = document.querySelectorAll(".field-error");
    errorMessages.forEach((message) => {
      message.classList.add("hidden");
    });
  }

  function showErrorMessages() {
    const errorMessages = document.querySelectorAll(".field-error");
    errorMessages.forEach((message) => {
      message.classList.remove("hidden");
    });
  }

  function setupBurgerMenu() {
    const burgerBtn = document.querySelector(".burger-btn");
    const menuLinks = document.querySelectorAll(".burger-menu__link");
    const mainContent = document.querySelector("main");
    const footer = document.querySelector("footer");

    if (burgerBtn) {
      burgerBtn.addEventListener("click", function () {
        document.body.classList.toggle("show-menu");
        const isMenuShown = document.body.classList.contains("show-menu");
        if (isMenuShown) {
          hideErrorMessages(); // Скрываем сообщения об ошибках
          mainContent.style.visibility = "hidden";
          footer.style.visibility = "hidden";
          burgerBtn.setAttribute("aria-label", "Close menu");
        } else {
          showErrorMessages(); // Показываем сообщения об ошибках
          mainContent.style.visibility = "visible";
          footer.style.visibility = "visible";
          burgerBtn.setAttribute("aria-label", "Open menu");
        }
      });

      menuLinks.forEach(function (link) {
        link.addEventListener("click", function () {
          document.body.classList.remove("show-menu");
          mainContent.style.visibility = "visible";
          footer.style.visibility = "visible";
          burgerBtn.setAttribute("aria-label", "Open menu");
          showErrorMessages(); // Показываем сообщения об ошибках при закрытии меню
        });
      });
    }
  }

  document.querySelectorAll('a[href^="#"]').forEach((link) => {
    link.addEventListener("click", function (e) {
      e.preventDefault();

      let href = this.getAttribute("href").substring(1);

      const scrollTarget = document.getElementById(href);

      const topOffset = document.querySelector("header").offsetHeight;
      const elementPosition = scrollTarget.getBoundingClientRect().top;
      const offsetPosition = elementPosition - topOffset;

      window.scrollBy({
        top: offsetPosition,
        behavior: "smooth",
      });
    });
  });

  function initPopups() {
    const body = document.querySelector("body");
    const openButtons = document.querySelectorAll(".open-btn");
    const closeButton = document.querySelector(".popup__btn-close");
    const popup = document.querySelector(".popup");
    const popupInner = document.querySelector(".popup__inner");
    const openRequestButtons = document.querySelectorAll(".open-btn--demo");
    const closeButtonPopup = document.querySelector(".popup-request__btn");
    const popupRequest = document.querySelector(".popup-request-a-demo");

    function showPopup(popup) {
      if (popup) {
        popup.style.visibility = "visible";
        body.style.overflow = "hidden";
        clearForm(popup);
      }
    }

    function hidePopup(popup) {
      if (popup) {
        popup.style.visibility = "hidden";
        body.style.overflow = "visible";
        clearForm(popup);
      }
    }

    function clearForm(popup) {
      const forms = popup.querySelectorAll("form");
      forms.forEach((form) => {
        form.reset();
        const feedbacks = form.querySelectorAll(".field-error");
        feedbacks.forEach((feedback) => {
          feedback.style.visibility = "hidden";
        });
        const inputs = form.querySelectorAll(".is-invalid, .is-valid");
        inputs.forEach((input) => {
          input.classList.remove("is-invalid", "is-valid");
        });
      });
      var dialcode = document.querySelector(".iti__selected-dial-code");
      if (dialcode) {
        dialcode.style.color = "";
      }
    }

    openButtons.forEach(function (button) {
      button.addEventListener("click", function () {
        showPopup(popup);
      });
    });

    if (closeButton) {
      closeButton.addEventListener("click", function () {
        hidePopup(popup);
      });
    }

    if (popup) {
      popup.addEventListener("click", function (event) {
        if (event.target === popup) {
          hidePopup(popup);
        }
      });
    }

    if (popupRequest) {
      popupRequest.addEventListener("click", function (event) {
        if (event.target === popupRequest) {
          hidePopup(popupRequest);
        }
      });
    }

    openRequestButtons.forEach(function (button) {
      button.addEventListener("click", function () {
        showPopup(popupRequest);
      });
    });

    if (closeButtonPopup) {
      closeButtonPopup.addEventListener("click", function () {
        hidePopup(popupRequest);
      });
    }
  }

  function validateField(input, errorMsg, message) {
    var value = input.value;

    if (input.type === "email") {
      var forbiddenPattern = /[\s()[\]:;,"\\<>]/;

      if (value.startsWith(".") || value.endsWith(".")) {
        showError(errorMsg, "Invalid work email");
        input.classList.add("is-invalid");
        input.classList.remove("is-valid");
        return false;
      }

      if (forbiddenPattern.test(value)) {
        showError(errorMsg, "Invalid work email");
        input.classList.add("is-invalid");
        input.classList.remove("is-valid");
        return false;
      }
    }

    var isValid = input.checkValidity();
    if (!isValid) {
      showError(errorMsg, message);
      input.classList.add("is-invalid");
      input.classList.remove("is-valid");
    } else {
      resetError(errorMsg);
      input.classList.remove("is-invalid");
      input.classList.add("is-valid");
    }
    return isValid;
  }

  function showError(errorMsg, message) {
    if (errorMsg) {
      errorMsg.style.visibility = "visible";
      errorMsg.textContent = message;
      if (
        errorMsg.previousElementSibling &&
        typeof errorMsg.previousElementSibling.setCustomValidity === "function"
      ) {
        errorMsg.previousElementSibling.setCustomValidity(message);
      }
    }
  }

  function resetError(errorMsg) {
    if (errorMsg) {
      errorMsg.style.visibility = "hidden";
      errorMsg.textContent = "0";
      if (
        errorMsg.previousElementSibling &&
        typeof errorMsg.previousElementSibling.setCustomValidity === "function"
      ) {
        errorMsg.previousElementSibling.setCustomValidity("");
      }
    }
  }

  function validateCheckbox(checkbox, errorMsgElement, errorMessage) {
    if (!checkbox.checked) {
      showError(errorMsgElement, errorMessage);
      checkbox.classList.add("is-invalid");
      return false;
    } else {
      resetError(errorMsgElement);
      checkbox.classList.remove("is-invalid");
      return true;
    }
  }
  function validateCvPhoneNumber(phoneInput, errorMsg) {
    const phonePattern = /^\+\d{1,3}\d{6,14}$/;
    const value = phoneInput.value;

    if (!phonePattern.test(value)) {
      showError(errorMsg, "Invalid phone number or format");
      phoneInput.classList.add("is-invalid");
      phoneInput.classList.remove("is-valid");
      return false;
    } else {
      resetError(errorMsg);
      phoneInput.classList.remove("is-invalid");
      phoneInput.classList.add("is-valid");
      return true;
    }
  }
  function initCvForm() {
    const cvForm = document.querySelector("#cv-form");
    const cvName = document.querySelector("#cv-name");
    const cvEmailInput = document.querySelector("#cv-email");
    const cvPosition = document.querySelector("#cv__position");
    const cvPhone = document.querySelector("#cv-phone");
    const cvLink = document.querySelector("#cv__url");
    const cvCheckbox = document.querySelector("#cv__check");
    const cvEmailErrorMsg = cvEmailInput
      ? cvEmailInput.nextElementSibling
      : null;
    const cvNameErrorMsg = cvName ? cvName.nextElementSibling : null;
    const cvPositionErrorMsg = cvPosition
      ? cvPosition.nextElementSibling
      : null;
    const cvPhoneErrorMsg = cvPhone ? cvPhone.nextElementSibling : null;
    const cvLinkErrorMsg = cvLink ? cvLink.nextElementSibling : null;
    const cvCheckboxErrorMsg = cvCheckbox
      ? cvCheckbox.nextElementSibling
      : null;

    if (!cvForm) {
      console.error("Cv form not found");
      return;
    }

    cvForm.addEventListener("submit", async function (event) {
      event.preventDefault();
      const body = document.querySelector("body");
      const cvTargetPopup = document.querySelector(".cv-message");

      const isEmailValid = validateField(
        cvEmailInput,
        cvEmailErrorMsg,
        "Invalid email"
      );
      const isNameValid = validateField(
        cvName,
        cvNameErrorMsg,
        "Your name is required"
      );
      const isPositionValid = validateField(
        cvPosition,
        cvPositionErrorMsg,
        "Position is required"
      );
      const isPhoneValid = validateCvPhoneNumber(
        cvPhone,
        cvPhoneErrorMsg,
        "Phone number is required"
      );
      const isLinkValid = validateField(
        cvLink,
        cvLinkErrorMsg,
        "Link is required"
      );
      const isCheckboxChecked = validateCheckbox(
        cvCheckbox,
        cvCheckboxErrorMsg,
        "You must agree to the terms"
      );
      const isFormValid = cvForm.checkValidity();

      if (
        !isEmailValid ||
        !isNameValid ||
        !isPositionValid ||
        !isPhoneValid ||
        !isLinkValid ||
        !isCheckboxChecked ||
        !isFormValid
      ) {
        if (!isFormValid) {
          Array.from(cvForm.elements).forEach(function (input) {
            marcerInput(input);
          });
        }
        return;
      }

      console.log("Validated cv form");
      try {
        let response = await emailjs.sendForm(
          "service_bgk6slz",
          "template_b4kyn8s",
          cvForm
        );
        console.log("SUCCESS!", response.status, response.text);
        cvTargetPopup.style.visibility = "visible";
        body.style.overflow = "hidden";
        cvForm.reset();

        setTimeout(() => {
          cvTargetPopup.style.visibility = "hidden";
          body.style.overflow = "auto";
        }, 5000);

        document.addEventListener("click", function () {
          if (cvTargetPopup.style.visibility === "visible") {
            cvTargetPopup.style.visibility = "hidden";
            body.style.overflow = "auto";
          }
        });
        document.addEventListener("keydown", function () {
          if (cvTargetPopup.style.visibility === "visible") {
            cvTargetPopup.style.visibility = "hidden";
            body.style.overflow = "auto";
          }
        });
      } catch (error) {
        console.error("FAILED...", error);
        alert("Sorry. Data not sent!");
      }
    });

    [cvName, cvPosition, cvLink].forEach(function (input) {
      if (input) {
        input.addEventListener("input", function () {
          resetError(input.nextElementSibling);
          input.classList.remove("is-invalid");
        });
        input.addEventListener("blur", function () {
          validateField(
            input,
            input.nextElementSibling,
            input.validationMessage
          );
        });
      }
    });

    cvEmailInput.addEventListener("input", function () {
      resetError(cvEmailErrorMsg);
      cvEmailInput.classList.remove("is-invalid");
    });

    cvEmailInput.addEventListener("blur", function () {
      validateField(cvEmailInput, cvEmailErrorMsg, "Invalid work email");
    });
    cvPhone.addEventListener("input", function () {
      resetError(cvPhoneErrorMsg);
      cvPhone.classList.remove("is-invalid");
    });

    cvPhone.addEventListener("blur", function () {
      validateCvPhoneNumber(cvPhone, cvPhoneErrorMsg, "Invalid phone");
    });
    cvCheckbox.addEventListener("input", function (event) {
      if (event.target.checked) {
        resetError(cvCheckboxErrorMsg);
        cvCheckbox.classList.remove("is-invalid");
      } else {
        showError(cvCheckboxErrorMsg, "You must agree to the terms");
        cvCheckbox.classList.add("is-invalid");
      }
    });
  }

  function validatePhoneNumber(phoneInput, phoneErrorMsg, iti) {
    var isValid = iti.isValidNumber();
    var dialcode = document.querySelector(".iti__selected-dial-code");
    if (!isValid) {
      showError(phoneErrorMsg, "Invalid phone number");
      phoneInput.classList.add("is-invalid");
      phoneInput.classList.remove("is-valid");
      dialcode.style.color = "rgb(204, 0, 73)";
    } else {
      resetError(phoneErrorMsg);
      phoneInput.classList.remove("is-invalid");
      phoneInput.classList.add("is-valid");
      dialcode.style.color = "";
    }
    return isValid;
  }
  function marcerInput(input) {
    if (!input.checkValidity()) {
      input.classList.add("is-invalid");
    } else {
      input.classList.remove("is-invalid");
    }
  }

  function initContactForm() {
    var form = document.querySelector(".popup .contact-form");
    var emailInput = document.querySelector("#form_email");
    var nameInput = document.querySelector("#form_name");
    var surnameInput = document.querySelector("#form_surname");
    var emailErrorMsg = emailInput ? emailInput.nextElementSibling : null;
    var nameErrorMsg = nameInput ? nameInput.nextElementSibling : null;
    var surnameErrorMsg = surnameInput ? surnameInput.nextElementSibling : null;

    if (!form) {
      console.error("Contact form not found");
      return;
    }

    form.addEventListener("submit", async function (event) {
      event.preventDefault();
      var body = document.querySelector("body");
      var targetPopup = document.querySelector(".popup");
      var isEmailValid = validateField(
        emailInput,
        emailErrorMsg,
        "Invalid work email"
      );
      var isNameValid = validateField(
        nameInput,
        nameErrorMsg,
        "First name is required"
      );
      var isSurnameValid = validateField(
        surnameInput,
        surnameErrorMsg,
        "Last name is required"
      );
      var isFormValid = form.checkValidity();

      if (!isEmailValid || !isNameValid || !isSurnameValid || !isFormValid) {
        if (!isFormValid) {
          Array.from(form.elements).forEach(function (input) {
            marcerInput(input);
          });
        }
        return;
      }

      console.log("Validated contact form");
      try {
        let response = await emailjs.sendForm(
          "service_bgk6slz",
          "template_b4kyn8s",
          form
        );
        console.log("SUCCESS!", response.status, response.text);
        alert("Form successfully submitted!");
        targetPopup.style.visibility = "hidden";
        body.style.overflow = "visible";
      } catch (error) {
        console.error("FAILED...", error);
        alert("Sorry. Data not sent!");
      }
    });

    [nameInput, surnameInput].forEach(function (input) {
      if (input) {
        input.addEventListener("input", function () {
          resetError(input.nextElementSibling);
          input.classList.remove("is-invalid");
        });
        input.addEventListener("blur", function () {
          validateField(
            input,
            input.nextElementSibling,
            input.validationMessage
          );
        });
      }
    });

    emailInput.addEventListener("input", function () {
      resetError(emailErrorMsg);
      emailInput.classList.remove("is-invalid");
    });

    emailInput.addEventListener("blur", function () {
      validateField(emailInput, emailErrorMsg, "Invalid work email");
    });
  }

  function initRequestForm() {
    var form = document.querySelector(".popup-request-a-demo .request-form");
    var emailInput = document.querySelector("#request_email");
    var nameInput = document.querySelector("#request_name");
    var surnameInput = document.querySelector("#request_surname");
    var companyInput = document.querySelector("#request_company");
    var jobInput = document.querySelector("#request_job");
    var sizeInput = document.querySelector("#request__size");
    var techInput = document.querySelector("#request__tech");
    var phoneInput = document.querySelector("#phone");
    var emailErrorMsg = emailInput ? emailInput.nextElementSibling : null;
    var nameErrorMsg = nameInput ? nameInput.nextElementSibling : null;
    var surnameErrorMsg = surnameInput ? surnameInput.nextElementSibling : null;
    var companyErrorMsg = companyInput ? companyInput.nextElementSibling : null;
    var jobErrorMsg = jobInput ? jobInput.nextElementSibling : null;
    var sizeErrorMsg = sizeInput ? sizeInput.nextElementSibling : null;
    var techErrorMsg = techInput ? techInput.nextElementSibling : null;
    var phoneErrorMsg = document.querySelector("#field-error");

    if (!form) {
      console.error("Request form not found");
      return;
    }

    var iti = window.intlTelInput(phoneInput, {
      initialCountry: "us",
      utilsScript:
        "https://cdn.jsdelivr.net/npm/intl-tel-input@23.3.2/build/js/utils.js",
      onlyCountries: [],
      autoHideDialCode: false,
      nationalMode: false,
      strictMode: true,
      validationNumberType: "MOBILE",
      separateDialCode: true,
      preferredCountries: ["us", "fr", "ca", "in", "au", "de", "nl"],
      customPlaceholder: function (
        selectedCountryPlaceholder,
        selectedCountryData
      ) {
        return "";
      },
    });

    phoneInput.addEventListener("countrychange", function () {
      var selectedCountryData = iti.getSelectedCountryData();
      phoneInput.placeholder =
        "+" + selectedCountryData.dialCode + "(XXX)XXX-XXXX";
    });

    form.addEventListener("submit", async function (event) {
      event.preventDefault();
      var body = document.querySelector("body");
      var targetPopup = document.querySelector(".popup-request-a-demo");
      var isEmailValid = validateField(
        emailInput,
        emailErrorMsg,
        "Invalid work email"
      );
      var isNameValid = validateField(
        nameInput,
        nameErrorMsg,
        "First name is required"
      );
      var isSurnameValid = validateField(
        surnameInput,
        surnameErrorMsg,
        "Last name is required"
      );
      var isCompanyValid = validateField(
        companyInput,
        companyErrorMsg,
        "Company name is required"
      );
      var isJobValid = validateField(
        jobInput,
        jobErrorMsg,
        "Job title is required"
      );
      var isSizeValid = validateField(
        sizeInput,
        sizeErrorMsg,
        "Please provide the size"
      );
      var isTechValid = validateField(
        techInput,
        techErrorMsg,
        "Please provide the technology"
      );
      var isPhoneValid = validatePhoneNumber(phoneInput, phoneErrorMsg, iti);
      var isFormValid = form.checkValidity();

      if (
        !isEmailValid ||
        !isNameValid ||
        !isSurnameValid ||
        !isCompanyValid ||
        !isJobValid ||
        !isSizeValid ||
        !isTechValid ||
        !isPhoneValid ||
        !isFormValid
      ) {
        if (!isFormValid) {
          Array.from(form.elements).forEach(function (input) {
            marcerInput(input);
          });
        }
        return;
      }

      console.log("Validated request form");
      try {
        let response = await emailjs.sendForm(
          "service_bgk6slz",
          "template_pjunhfm",
          form
        );
        console.log("SUCCESS!", response.status, response.text);
        alert("Form successfully submitted!");
        targetPopup.style.visibility = "hidden";
        body.style.overflow = "visible";
      } catch (error) {
        console.error("FAILED...", error);
        alert("Sorry. Data not sent!");
      }
    });

    [
      nameInput,
      surnameInput,
      companyInput,
      jobInput,
      sizeInput,
      techInput,
      phoneInput,
    ].forEach(function (input) {
      if (input) {
        input.addEventListener("input", function () {
          resetError(input.nextElementSibling);
          input.classList.remove("is-invalid");
        });
        input.addEventListener("blur", function () {
          validateField(
            input,
            input.nextElementSibling,
            input.validationMessage
          );
        });
      }
    });

    emailInput.addEventListener("input", function () {
      resetError(emailErrorMsg);
      emailInput.classList.remove("is-invalid");
    });

    emailInput.addEventListener("blur", function () {
      validateField(emailInput, emailErrorMsg, "Invalid work email");
    });

    phoneInput.addEventListener("input", function () {
      resetError(phoneErrorMsg);
      phoneInput.classList.remove("is-invalid");
    });

    phoneInput.addEventListener("blur", function () {
      validatePhoneNumber(phoneInput, phoneErrorMsg, iti);
    });
  }

  const accordionFunction = () => {
    const accordionItems = document.querySelectorAll(".accord-item");

    accordionItems.forEach((item) => {
      item.addEventListener("click", function () {
        item.classList.toggle("active");
      });
    });
  };

  includeHTML(function () {
    initPopups();
    initContactForm();
    initRequestForm();
    accordionFunction();
    initCvForm();
    setupBurgerMenu();
    highlightActiveLink();
    deleteAnchor();
  });

  // Это чтобы после отправки формы, появился thk-overlay
  const contactForm = document.querySelector("#contactForm");

  if (contactForm) {
    contactForm.addEventListener("submit", function (event) {
      const thkOverlay = document.querySelector(".thk-overlay");

      if (!thkOverlay) {
        return;
      }

      event.preventDefault();
      thkOverlay.classList.add("thk-overlay--active");
    });
  }

  // бургер меню
  const burgerBtn = document.querySelector(".burger-button");
  const headerMenu = document.querySelector(".header__menu");

  function closeMenu() {
    burgerBtn.classList.remove("burger-button--active");
    headerMenu.classList.remove("header__menu--active");
  }

  burgerBtn.addEventListener("click", () => {
    burgerBtn.classList.toggle("burger-button--active");
    headerMenu.classList.toggle("header__menu--active");
  });

  window.addEventListener("resize", () => {
    if (window.innerWidth > 1179) {
      closeMenu();
    }
  });

  //details component
  const detailsElements = document.querySelectorAll(".main-details");

  detailsElements.forEach((details) => {
    details.addEventListener("toggle", () => {
      if (details.open) {
        details.classList.add("main-details--open");
      } else {
        details.classList.remove("main-details--open");
      }
    });
  });
});

(function () {
  const iframe = document.getElementById('video-home');
  if(iframe) {
      const player = new Vimeo.Player(iframe);
      const muteButton = document.getElementById('mute-toggle');
      const playButton = document.getElementById('play-toggle');
      const progressSlider = document.getElementById('progress-slider');

      player.setVolume(0);

      const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
              if (entry.isIntersecting) {
                  player.play().catch(function (error) {
                      console.log('Error autoplaying video:', error);
                  });
              } else {
                  player.pause();
              }
          });
      }, {threshold: 0.5});

      observer.observe(iframe);

      muteButton.addEventListener('click', async () => {
          const volume = await player.getVolume();

          if (volume > 0) {
              player.setVolume(0);
              muteButton.innerHTML = '<img src="src/img/icons/mute-off.svg" alt="Unmute" />';
          } else {
              player.setVolume(1);
              muteButton.innerHTML = '<img src="src/img/icons/mute-on.svg" alt="Mute" />';
          }
      });

      playButton.addEventListener('click', async () => {
          const isPlaying = await player.getPaused();

          if (isPlaying) {
              player.play();
              playButton.innerHTML = '<img src="src/img/icons/pause.svg" alt="Pause" />';
          } else {
              player.pause();
              playButton.innerHTML = '<img src="src/img/icons/play.svg" alt="Play" />';
          }
      });

      player.getDuration().then(duration => {
          progressSlider.max = duration;
      });

      player.on('timeupdate', ({seconds}) => {
          progressSlider.value = seconds;
      });

      progressSlider.addEventListener('input', () => {
          player.setCurrentTime(progressSlider.value);
      });
  }
})();

window.addEventListener('load', () => {
  if (window.location.search.includes('waitlist')) {
    const waitlistForm = document.getElementById('waitlist');
    if (waitlistForm) {
      waitlistForm.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
});
